import React from "react";
import { injectIntl } from "gatsby-plugin-intl";
import {ORGANISATION_TAB, PARTNER_TAB} from "./constants";

const PricingTabSelector = (props) => {
    
    return <div className="pricing-tab-selector row justify-content-center">
        <div className={props.activeTab === ORGANISATION_TAB ? "col-md-6 tab active" : "col-md-6 tab"}>
            <button onClick={() => props.changeView(ORGANISATION_TAB)}>{props.intl.formatMessage({id: 'Organisations'})}</button>
        </div>
        <div className={props.activeTab === PARTNER_TAB ? "col-md-6 tab active" : "col-md-6 tab"}>
            <button onClick={() => props.changeView(PARTNER_TAB)}>Partners</button>
        </div>
    </div>
}

export default injectIntl(PricingTabSelector);
