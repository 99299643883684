// System
import React from "react";
import { injectIntl } from "gatsby-plugin-intl";

// Layout
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Section from "../../components/section";

// Components
import GradientBanner from "../../components/gradient-banner";
import PricingWidget from "../../components/pricing/pricing-widget";
import AnyQuestions from "../../components/any-questions";

// Styles
import "../../styles/pricing.less";

const Pricing = (props) => {
  return <Layout businessUp test staticHeader>
    <SEO 
		title={props.intl.formatMessage({id: "Prices and plans - BusinessUp by Adventurees"})} 
		description={props.intl.formatMessage({id: "Discover the different prices and BusinessUp plans specially designed for your organization"})}
		location={props.location}
	/>

    <GradientBanner 
        position={'at top center'}
        shape={'circle'}
        extent={'farthest-corner'}
        colors={['#599dde', '#4570b4']}
        className="banner gradient-banner" 
        title={props.intl.formatMessage({id: "Find the right model for you"})} 
        textAlignment={"center"}
        colSize={"col-md-10"}
        verticalAlignment={"start"}
    />
    <Section className="pricing">
        <PricingWidget />
    </Section>
    <Section className="pricing-additional-help" title={props.intl.formatMessage({id: "Do you need additional help to build your platform?"})}>
      	<div className="row justify-content-center">
		  	<div className="col-lg-5 item">
				<div className="row">
					<div className="img-container col-2">
						<img src="/images/pricing/gray-screen-paint.png" alt={props.intl.formatMessage({id: "Custom designs and developments"})} />
					</div>
					<div className="col-xs-12 col-sm-10">
						<h3 className="item-title">{props.intl.formatMessage({id: "Custom designs and developments"})}</h3>
						<p className="item-description">{props.intl.formatMessage({id: "We integrate your own design and adapt the platform technically and functionally to suit your needs perfectly."})}</p>
					</div>
				</div>
			</div>
			<div className="col-lg-5 item">
				<div className="row">
					<div className="img-container col-2">
						<img src="/images/pricing/gray-training.png" alt={props.intl.formatMessage({id: "Training"})} />
					</div>
					<div className="col-xs-12 col-sm-10">
						<h3 className="item-title">{props.intl.formatMessage({id: "Training"})}</h3>
						<p className="item-description">{props.intl.formatMessage({id: "You can also hire our Mentoring & Training services and a variety of training courses focused on developing companies, entrepreneurship and financing."})}</p>
					</div>
				</div>
			</div>
			<div className="col-lg-5 item">
				<div className="row">
					<div className="img-container col-2">
						<img src="/images/pricing/gray-screen-gear.png" alt={props.intl.formatMessage({id: "Consulting and technical assistance"})} />
					</div>
					<div className="col-xs-12 col-sm-10">
						<h3 className="item-title">{props.intl.formatMessage({id: "Consulting and technical assistance"})}</h3>
						<p className="item-description">{props.intl.formatMessage({id: "We can assist you to give a better service to your community by filtering projects, Quick Analysis, evaluations and advice on proposals to investors and financing."})}</p>
					</div>
				</div>
			</div>
			<div className="col-lg-5 item">
				<div className="row">
					<div className="img-container col-2">
						<img src="/images/pricing/gray-hand-with-money.png" alt={props.intl.formatMessage({id: "Promotion to investors and financing rounds"})} />
					</div>
					<div className="col-xs-12 col-sm-10">
						<h3 className="item-title">{props.intl.formatMessage({id: "Promotion to investors and financing rounds"})}</h3>
						<p className="item-description" dangerouslySetInnerHTML={{__html: props.intl.formatMessage({id: "Service provided by <a href='https://www.adventurees.com' target='_blank' rel='noopener noreferrer'>Adventurees.com</a> as an investment platform authorized by the CNMV (Spanish Market regulator). Available for European projects and Worldwide investors."})}}></p>
					</div>
				</div>
			</div>
			<div className="col-lg-5 item">
				<div className="row">
					<div className="img-container col-2">
						<img src="/images/pricing/business-up-donations-crowdfunding.png" alt={props.intl.formatMessage({id: "Donations Crowdfunding"})} />
					</div>
					<div className="col-xs-12 col-sm-10">
						<h3 className="item-title">{props.intl.formatMessage({id: "Donations Crowdfunding"})}</h3>
						<p className="item-description">{props.intl.formatMessage({id: "We set up your own crowdfunding platform for donations and rewards. Move your community to finance projects by making donations or offering them rewards in exchange. All under your brand."})}</p>
					</div>
				</div>
			</div>
			<div className="col-lg-5 item">
				<div className="row">
					<div className="img-container col-2">
						<img src="/images/pricing/business-up-investment-crowdfunding.png" alt={props.intl.formatMessage({id: "Investment Crowdfunding"})} />
					</div>
					<div className="col-xs-12 col-sm-10">
						<h3 className="item-title">{props.intl.formatMessage({id: "Investment Crowdfunding"})}</h3>
						<p className="item-description" dangerouslySetInnerHTML={{__html: props.intl.formatMessage({id: "Our software allows you to manage stock purchases, debt and other advanced investment modalities. You will also be able to manage the entire loan repayment process through the platform. All under your brand. You can see our own platform at <a href='https://www.adventurees.com' target='_blank' rel='noopener noreferrer'>www.adventurees.com</a>"})}}></p>
					</div>
				</div>
			</div>
		</div>
    </Section>
	<AnyQuestions />
  </Layout>
}

export default injectIntl(Pricing);