import React from "react";
import { Link, injectIntl } from "gatsby-plugin-intl";
import AdvTitle from "../advTitle";

const PricingTabPartner = (props) =>
{
    return <div className="partner-tab">
        <AdvTitle title="Partners" />
        <div className="diagram-container">
            <img src={"/images/pricing/partner-diagram-"+props.intl.locale+".png"} alt="Partner" />
        </div>
        <div className="advantages">
            <h3 className="pricing-title">{props.intl.formatMessage({id: "Advantages of being partner:"})}</h3>
            <div className="row justify-content-around">
                <div className="col-md-5 advantage">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center advantage-icon">
                            <img src="/images/pricing/check-icon.png" alt="advantage" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center ">
                            <span>{props.intl.formatMessage({id: "Incorporate Adventurees' tools into the customer's value proposition"})}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 advantage">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center advantage-icon">
                            <img src="/images/pricing/check-icon.png" alt="advantage" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center ">
                            <span>{props.intl.formatMessage({id: "Ability to create unlimited platforms from a central account"})}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 advantage">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center advantage-icon">
                            <img src="/images/pricing/check-icon.png" alt="advantage" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center ">
                            <span>{props.intl.formatMessage({id: "Join a global network of entrepreneurial ecosystems"})}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 advantage">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center advantage-icon">
                            <img src="/images/pricing/check-icon.png" alt="advantage" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center ">
                            <span>{props.intl.formatMessage({id: "Access to the Adventurees network of investors"})}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="contact-trigger">
            <p>{props.intl.formatMessage({id: "Contact us and we will schedule a demo to show you how to manage your customers' websites"})}</p>
            <div className="form-trigger">
                <Link to="/contact">{props.intl.formatMessage({id: "Contact us"})}</Link>
            </div>
        </div>
    </div>
}

export default injectIntl(PricingTabPartner);