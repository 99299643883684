import React from "react";
import { Link, injectIntl } from "gatsby-plugin-intl";
import AdvTitle from "../advTitle";
import params from "../../data/params";


const PricingTabOrganisation = (props) => 
{
    return <div className="organisation-tab">
        <AdvTitle title={props.intl.formatMessage({id: "Organisations"})} />
        <div className="plan-price">
            <p>{props.intl.formatMessage({id: "Try it now for free and pay only on activation"})}</p>
            <span className="price-amount">
                149€ / {props.intl.formatMessage({id: "month"})}
            </span>
            <a href={params.DO_CREATE_URL} target="_blank" rel="noopener noreferrer">{props.intl.formatMessage({id: "Try it for free"})}</a>
        </div>
        
        <div className="functionalities">
            <h3 className="pricing-title">{props.intl.formatMessage({id: "Every plan includes:"})}</h3>
            <div className="row justify-content-around">
                <div className="col-md-5 functionality">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center functionality-icon">
                            <img src="/images/functionalities/own-domain.png" alt="functionality" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center functionality-text">
                            <span>{props.intl.formatMessage({id: "Usage of your own domain"})}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 functionality">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center functionality-icon">
                            <img src="/images/functionalities/own-design.png" alt="functionality" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center functionality-text">
                            <span>{props.intl.formatMessage({id: "Brand Look&Feel"})}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 functionality">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center functionality-icon">
                            <img src="/images/functionalities/own-content.png" alt="functionality" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center functionality-text">
                            <span>{props.intl.formatMessage({id: "Own data and contents"})}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 functionality">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center functionality-icon">
                            <img src="/images/functionalities/editable-pages.png" alt="functionality" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center functionality-text">
                            <span>{props.intl.formatMessage({id: "Editable pages"})}</span>
                        </div>
                    </div>						
                </div>
                <div className="col-md-5 functionality">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center functionality-icon">
                            <img src="/images/functionalities/project-filtering.png" alt="functionality" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center functionality-text">
                            <span>{props.intl.formatMessage({id: "Reception and filtering projects"})}</span>
                        </div>
                    </div>	
                </div>
                <div className="col-md-5 functionality">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center functionality-icon">
                            <img src="/images/functionalities/goldsmith-analysis.png" alt="functionality" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center functionality-text">
                            <span>{props.intl.formatMessage({id: "Goldsmith® model analysis"})}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 functionality">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center functionality-icon">
                            <img src="/images/functionalities/kpis.png" alt="functionality" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center functionality-text">
                            <span>{props.intl.formatMessage({id: "Own diagnostic KPIs"})}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 functionality">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center functionality-icon">
                            <img src="/images/functionalities/businesses-document-management.png" alt="functionality" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center functionality-text">
                            <span>{props.intl.formatMessage({id: "Business document management"})}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 functionality">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center functionality-icon">
                            <img src="/images/functionalities/online-mentoring-space.png" alt="functionality" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center functionality-text">
                            <span>{props.intl.formatMessage({id: "Online mentoring space"})}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 functionality">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center functionality-icon">
                            <img src="/images/functionalities/business-showcase.png" alt="functionality" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center functionality-text">
                            <span>{props.intl.formatMessage({id: "Business showcase"})}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 functionality">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center functionality-icon">
                            <img src="/images/functionalities/events-and-community.png" alt="functionality" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center functionality-text">
                            <span>{props.intl.formatMessage({id: "Events and community"})}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 functionality">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center functionality-icon">
                            <img src="/images/functionalities/autonomy.png" alt="functionality" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center functionality-text">
                            <span>{props.intl.formatMessage({id: "100% autonomous administration"})}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 functionality">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center functionality-icon">
                            <img src="/images/functionalities/admin-support.png" alt="functionality" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center functionality-text">
                            <span>{props.intl.formatMessage({id: "Administrator training and support"})}</span>
                        </div>
                    </div>
                </div>
                <div className="col-md-5 functionality">
                    <div className="row justify-content-center">
                        <div className="col-2 align-self-center functionality-icon">
                            <img src="/images/functionalities/languages.png" alt="functionality" />
                        </div>
                        <div className="col-md-10 col-7 align-self-center functionality-text">
                            <span>{props.intl.formatMessage({id: "Available in Spanish, English and Portuguese"})}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default injectIntl(PricingTabOrganisation);