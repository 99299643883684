import React from "react";
import PropTypes from 'prop-types';

import Button from "./button";
import "../styles/banner.less";

const GradientBanner = (props) => {
return <div style={{
        textAlign: props.textAlignment,
        backgroundImage: `radial-gradient(${props.shape} ${props.extent} ${props.position}, ${props.colors.join(', ')})`
    }}
    export default
    className={props.className}
    fadeIn={false}
    >
        <div className = "container">
            <div className={"row align-items-"+props.verticalAlignment+" justify-content-"+props.textAlignment}>
                <div className={props.colSize ? 'col-sm-12 '+props.colSize : 'col-sm-12 col-md-6'}>
                    {props.dangerous && props.dangerous.title && <h1 dangerouslySetInnerHTML={{__html: props.title}}></h1>}
                    {(!props.dangerous || !props.dangerous.title) && <h1>{props.title}</h1>}

                    {props.dangerous && props.dangerous.subtitle && <p dangerouslySetInnerHTML={{__html: props.subtitle}}></p>}
                    {(!props.dangerous || !props.dangerous.subtitle) && <p className="banner-subtitle">{props.subtitle}</p>}

                    {props.button &&
                        <div className="main-call-to-action">
                            <Button text={props.button.text} url={props.button.url} note={props.button.note} />
                        </div>
                    }
                </div>
            </div>
        </div>
    </div>
}

GradientBanner.propTypes = {
    textAlignment: PropTypes.string,
    shape: PropTypes.string.isRequired,
    extent: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    colors: PropTypes.array.isRequired,
    className: PropTypes.string,
    verticalAlignment: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    dangerous: PropTypes.shape({
        title: PropTypes.bool,
        subtitle: PropTypes.bool
    }),
    button: PropTypes.shape({
        text: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
        note: PropTypes.string
    })
}

export default GradientBanner;