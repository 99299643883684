import React from "react";

import {ORGANISATION_TAB, PARTNER_TAB} from "./constants";
import PricingTabOrganisation from "./pricing-tab-organisation";
import PricingTabPartner from "./pricing-tab-partner";

const PricingTabContainer = (props) => (
	<div className="pricing-tab-container">
		{(() => {
			switch(props.activeTab)
			{
				case ORGANISATION_TAB:
					return <PricingTabOrganisation />
				case PARTNER_TAB:
					return <PricingTabPartner />
				default:
					return <PricingTabOrganisation />
			}
		})()}
	</div>
)

export default PricingTabContainer;
