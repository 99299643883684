import React, { useState } from "react";
import { injectIntl } from 'gatsby-plugin-intl';

import PricingTabSelector from "./pricing-tab-selector";
import PricingTabView from "./pricing-tab-container";
import {ORGANISATION_TAB, PARTNER_TAB} from './constants';
import "../../styles/pricing-widget.less";



const PricingWidget = (props) =>
{
    const [activeTab, setActiveTab] = useState(ORGANISATION_TAB);

    
    return <div className="pricing-widget">
        <div className="row justify-content-center">
            <div className="col-md-4">
                <PricingTabSelector changeView={(view) => setActiveTab(view)} activeTab={activeTab} />
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-md-6">
                {(() => {
                    switch(activeTab)
                    {
                        case PARTNER_TAB:
                            return <p className="chosen-tab-subtitle">{props.intl.formatMessage({id: "Perfect for agencies and consulting firms"})}</p>
                        case ORGANISATION_TAB:
                            return <p className="chosen-tab-subtitle">{props.intl.formatMessage({id: "Adapted to all types of organizations"})}</p>
                        default:
                            return <p className="chosen-tab-subtitle">{props.intl.formatMessage({id: "We offer plans for all types and sizes of organizations"})}</p>
                    }
                })()}
            </div>
        </div>
        <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12 pricing-col">
                <PricingTabView activeTab={activeTab}/>
            </div>
        </div>
    </div>
}

export default injectIntl(PricingWidget);